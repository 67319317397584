<template>
    <div class="container">

        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <EditWLGroup />
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            </div>
        </div>
        </div>

        <ModalWorkHistory inputId="modalHistory"/>

        <div class="title-header">
        <div class="row px-3">
            <div class="col-sm-8">
            <h2>
                <template v-if="selectedWorkPosition">

                    <span v-if="workOrder" class="badge bg-primary">{{workOrder.code}}</span>
                    <span class="badge bg-primary">{{getLocationFullindex(selectedWorkPosition)}}</span>

                    <span class="ms-1" v-for="(name, index) in fullNameList(selectedWorkPosition)" :key="index">
                        <span :class="{'badge':true, 'bg-secondary':true }">
                            {{name}}
                        </span>
                        ❯
                    </span>
                    <span class="ms-1 badge bg-primary">{{getWorkablePositionName(selectedWorkPosition)}}</span>
                </template>

                <!-- <span class="badge bg-primary">{{workOrder.code}}</span>
                <span class="ms-1 badge bg-primary">Radna lokacija 1</span>
                <span class="ms-1 badge bg-info">Grupa 1</span> -->
            </h2>
            </div>

            <div class="col-sm-4">
            <div class="row">
                <div class="col-sm-6">
                <div class="d-grid gap-2">
                    <!-- <button type="button" class="btn-header btn-block btn-primary btn-lg"><i class="fas fa-file-excel fa-lg me-1" /> Export Excel</button> -->
                </div>
                </div>
                <div class="col-sm-6">
                <div class="d-grid gap-2">
                    <!-- TODO -->
                    <!-- <button disabled type="button" class="btn-header btn-block btn-primary btn-lg" v-on:click="editWLGroup"><i class="fas fa-edit fa-lg me-1" /> Izmeni posao</button> -->
                    <!-- <button disabled type="button" class="btn-header btn-block btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#exampleModal" v-on:click="editWLGroup"><i class="fas fa-edit fa-lg me-1" /> Izmeni posao</button> -->
                </div>
                </div>
            </div>

            </div>


        </div>



        </div>
        
        
        <VueNestable
            v-model="workPositionLocations"
            class-prop="class"
            childrenProp="children"
            class="mb-5"
            >

            <template slot-scope="{ item }">
                <div v-show="!item.hide" :set="workable = isLocationWorkable(item)">
                    <!-- Regular view -->        
                    <div class="clearfix" :class="[workClass(item.work), workable?'workable':'not-workable']">

                        <div class="item-text clearfix d-inline-block">
                        <a href="#" v-if="item.workable" data-bs-toggle="modal" data-bs-target="#modalHistory"><span>{{item.fullIndex}} {{ item.name }}</span></a>
                        
                        
                        <span v-if="workable"><span class="ms-2 badge bg-primary">{{item.fullIndex}}</span> {{ item.name }}</span>
                        <span v-else :class="'ms-2 badge ' + workStatusClass(item.status)">{{item.fullIndex}} {{ item.name }}</span>

                        </div>

                        <div v-if="workable" class="item-button-set d-inline float-end">

                            <template v-if="item.work">
                                <!-- <span class="worker">
                                    <router-link :to="{ name: 'Workers'}">Marko Markovic</router-link>
                                </span>                
                                <span class="date">12.5.2020.</span> -->
                                
                                <span v-if="item.work" class="ms-1 badge bg-primary" :class="workClass(item.work)">{{workLabel(item.work)}}</span>

                                <router-link v-if="user.role !== 'WORKER' && workStatusStage(item.work) == 1" type="button" class="btn btn-primary ms-2"
                                    :to="{ name: 'JobAssignment', query:{'workOrderId': workOrderID, 'workLocationId': item.id, 'workPositionId': workPositionID}}"
                                ><i class="fas fa-hard-hat"></i></router-link>
                            </template>
                                <button type="button" class="btn btn-info" v-on:click="loadWorkHistory(item)" data-bs-toggle="modal" data-bs-target="#modalHistory"><i class="fas fa-info-circle"></i></button>

                        </div>

                    </div>
                </div>

            </template>


        </VueNestable>
    </div>
</template>

<script>
require('@/assets/vue-nestable.css')
require('@/assets/tree-view.css')
import UtilService from '@/service/UtilService.js';
import EditWLGroup from '@/components/EditWLGroup.vue';
import ModalWorkHistory from '@/components/modals/ModalWorkHistory.vue';
import WorkOrderService from '@/service/WorkOrderService.js';
import WorkPositionService from '@/service/WorkPositionService.js';
import {mapState} from 'vuex'

export default {
    components: {
        EditWLGroup,
        ModalWorkHistory
    },

    data () {
        return {

            workPositionLocationsCopy: [],
            works: [],
            worksMap: undefined,
            workPositionLocations: [],
            selectedWorkPosition: null,

        }
    },  
    computed: {
        ...mapState([
        'position_units',
        'workOrder',
        'user'
        ]),
        

        workOrderID() {
        return parseInt(this.$route.params.woId)
        },

        workPositionID() {
        return parseInt(this.$route.params.wpId)
        },

        workLocationsData() {
            return this.work_locationPositions;
        },

        // getTreeList() {
        // let result = [];
        // let explore = JSON.parse(JSON.stringify(this.work_locations));
        // let current;
        // while(explore.length != 0){
        //     current = explore[0];
        //     explore.shift();
        //     explore.push.apply(explore, current.children);
        //     result.push({id: current.id, name: current.name, depth: current.depth, fullIndex: current.fullIndex});
        // }
        // return result.sort((a, b) => (a.fullIndex > b.fullIndex) ? 1 : -1);
        // },

    },
    methods: {
        workLabel : UtilService.workLabel,
        workClass : UtilService.workClass,
        workStatusLabel : UtilService.workStatusLabel,
        workStatusClass : UtilService.workStatusClass,
        workStatusStage : UtilService.workStatusStage,
        fullNameList : UtilService.fullNameList,
        getLocationFullindex : UtilService.getLocationFullindex,

        getWorkStatusLabel (work) {
            return this.workStatusLabel(work.workStatus);
        },
        getWorkStatusClass (work) {
            return this.workStatusClass(work.workStatus);
        },


        async loadPositionLocations() {
            const response = await WorkOrderService.getWorkOrderLocationsTree(this.workOrderID);
            this.workPositionLocationsCopy = response.data;

            this.$store.dispatch('loadPositionUnitsIfUndefined').then(() => {
                UtilService.recalculateLocationFullIndex(this.workPositionLocationsCopy)
                UtilService.scanUnnecessaryChildrenForGroup(this.workPositionLocationsCopy, this.selectedWorkPosition.root_position.id)
            })

            await this.loadWorks();

            this.worksMap = this.worksToMap();

            // this.work_locationPositionsCopy = this.work_locationPositions;
            this.mergeLocationsAndWorks(this.workPositionLocationsCopy);
            this.workPositionLocations = this.workPositionLocationsCopy;

        },

        worksToMap() {
            let positionWorks = new Map();

            this.works.forEach(work => {
                if (!positionWorks.has(work.work_location_id)){
                    positionWorks.set(work.work_location_id, []);
                }

                positionWorks.get(work.work_location_id).push(work);
            });
            return positionWorks;
        },


        mergeLocationsAndWorks(obj) {
            let finished_all = true;

            for(let i = 0 ; i < obj.length ; i++) {
                let result = false;

                if (obj[i].children.length > 0){
                    result = this.mergeLocationsAndWorks(obj[i].children);
                    if (!result) finished_all = false;
                }


                if (this.isLocationWorkable(obj[i])){
                    obj[i].work = this.findLastWork(obj[i].id);
                    if (obj[i]?.work?.workStatus != "APPROVED") finished_all = false;
                    
                // } else {
                //     obj[i].finished = result;
                }

                
            }
            


            if (finished_all) obj.finished = true;
            return finished_all;
        },

        isLocationWorkable(location) {
            if (location.position_group_id == this.selectedWorkPosition?.root_position?.id)
                return true;
            return false;
        },

        getWorkStatusStage(workStatus) {
            if (workStatus == "NOT_SUBMITTED" || workStatus == "REFUSED") return 0;
            if (workStatus == "IN_PROGRESS" || workStatus == "DONE" || workStatus == "NOT_DONE") return 1;
            if (workStatus == "APPROVED") return 2;
            return 0;
        },

        findLastWork(workLocationId) {
            if (!this.worksMap.has(workLocationId))
                return null;

            let works = this.worksMap.get(workLocationId);
            if (works.length == 0) return null
            if (works.length == 1) return works[0]

            let result = works.reduce(function(prev, curr) {
                return prev.job_assignment.started_date_time > curr.job_assignment.started_date_time ? prev : curr;
            });


            return result;

        },

        getClassForLocation(item) {
            if (item.finished) return 'bg-success';

            if (!item.work) return '';
            
            if (item.work.workStatus == 'APPROVED') return 'work-bg-approved';
            if (item.work.workStatus == 'DONE') return 'work-bg-checked';
            if (item.work.workStatus == 'NOT_DONE') return 'work-bg-not-started';
            if (item.work.workStatus == 'REJECTED') return 'work-bg-rejected';
            
        },

        async loadWorks() {
            const response = await WorkPositionService.getWorksInWorkPosition({workPositionId: this.workPositionID});
            this.works = response.data;    

        },

        async loadWorkHistory(workLocation) {
            this.$store.dispatch('loadLocationPositionWorks', {workLocationId: workLocation.id, workPositionId: this.workPositionID});
            this.$store.dispatch('loadNormative', {typeId: workLocation.type_id, workPositionId: this.workPositionID});
            this.$store.dispatch('loadWorkPosition', {workPositionId: this.workPositionID});
            this.$store.dispatch('loadWorkLocation', {workLocationId: workLocation.id});
        },

        async loadWorkPosition() {
            try {
                const result = await WorkPositionService.getWorkPositionParents({workPositionId: this.workPositionID})
                this.selectedWorkPosition = result.data
            } catch (error) {
                console.log(error)
            }
        },

        getWorkablePositionName(item) {
            if (!item.position_unit) return item.name;

            var name = item.position_unit.name
            if (item.name) name += ' (' + item.name + ')';
            return name;
        },

    },
    created() {
        this.loadPositionLocations();
        this.loadWorkPosition();
        


    

        // this.$store.dispatch('loadWorkPosition', {workPositionId: this.workPositionID, prepareParents: true});
        this.$store.dispatch('getWorkOrder', {id: this.workOrderID});
    }
}
</script>

<style>

    .item-button-set span {
    margin-left: 10px;
    }

    .item-button-set button {
    margin-left: 10px;
    }

    .edit-commands {
        position: sticky;
        top: 0;
        background: white;
        z-index: 10;
        max-height: 50vh;
        /* overflow-x: hidden; */
        overflow-y: auto;
        box-shadow: #00000026 0 6px 20px;
        margin: 0 -50px;
        padding: 0 50px;
        resize: vertical;
        
    }


    .title-header{
        padding: 1em 0;
        background: #299be4;
        color: #fff;
        margin: 10px 0px 0;
    }
    .title-header h2{
        margin: 5px 0 0;
        font-size: 1.8em;
    }
    .title-header .btn-header {
        color: #566787;
        float: right;
        font-size: 13px;
        background: #fff;
        border: none;
        min-width: 50px;
        border-radius: 2px;
        border: none;
        outline: none !important;
        /* margin-left: 10px; */
    }
    .title-header .btn-header:hover, .title-header .btn-header:focus {
        color: #566787;
        background: #f2f2f2;
    }

    .title-header .btn-header .btn-title {
        margin-top: 20px;
    }

    .nestable .work-bg-approved {
        background: #38f157
    }

    .nestable .work-bg-checked {
        background: #cfcfcf
    }

    .nestable .work-bg-not-started {
        background: #cfcfcf
    }

    .nestable .work-bg-rejected {
        background: #ff5b5b
    }


  </style>