<template>
  <div class="workorder-page">
    <Navbar />
    <Breadcrumbs></Breadcrumbs>
    <WorkPosition/>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import WorkPosition from '@/components/work-positions/WorkPosition.vue'
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Navbar,
    WorkPosition,
    Footer
  }
}
</script>
